import request from "@/utils/request";

const {ElMessage} = require("element-plus");

export default {
    name: "adminzd",

    components: {

    },

    data() {


        return {
            selectedYear: '', // 存储选中的年份
            selectedMonth: '', // 存储选中的月份
            dateRange: [], // 存储日期范围选择器的值
            creationtimeFilter: '', // 存储月份筛选器的值
            currentPageDialog: 1,
            pageSizeDialog: 10,
            loading: true,

            disabled: false,
            judge: false,
            dialogVisible: false,
            detailDialog: false,
            search: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            totalDialog: 0,
            tableData: [],
            tableData1: [],
            detail: {},
            form: {
                id: "",
                deliverytime:"",
                customer: "",
                writers: "",
                creationtime: "",
                customerID: "",
                finish: "",
                audit: "",
                remark: "",
                collection: "",
                payment: "",
                serve: "",
                demand: "",
                manner: "",
                settlement: "",
                incentive: "",
                ordernumber: "",
            },


            rules: {
            },
        };
    },

    created() {


        this.load();
        this.loading = true;
        setTimeout(() => {
            //设置延迟执行
            this.loading = false;
        }, 1000);

    },
    methods: {
        // 处理日期范围选择器值变化
        handleDateRangeChange(value) {
            // value 是一个数组，包含开始日期和结束日期
            // 在这里根据选中的日期范围进行数据筛选或发起查询
        },
        // 处理月份筛选器值变化
        handleCreationtimeFilterChange(value) {
            // value 是选中的月份
            // 在这里根据选中的月份进行数据筛选或发起查询
        },


        async load() {
            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;
            this.search = ""
            request.get("/building/admonthlySummary", {
                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    search: '',
                },

            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;

            });

        },

        handleEdit(row) {
            //获取详情


        },
        save(row){
            alert( row.settlement)
            alert( row.creationtime)
            request.put("/building/updateYGZD", row).then((res) => {
                console.log(res);
                if (res.code === "0") {
                    ElMessage({
                        message: "修改成功",
                        type: "success",
                    });
                    this.search = "";
                    this.load();
                    this.dialogVisible1 = false;
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },

        async load1(row) {
            try {
                if (!row || !row.creationtime || !row.settlement) {
                    console.error("Invalid row data:", row);
                    return;
                }

                const creationtime = row.creationtime;
                const settlement = row.settlement;
                const userData = JSON.parse(window.sessionStorage.getItem("user"));
                const userName = row.writers;

                const res = await request.get("/building/xq", {
                    params: {
                        pageNum: this.currentPageDialog,
                        pageSize: this.pageSizeDialog,
                        Writers: userName,
                        Deliverytime: creationtime,
                        settlement: settlement,
                    },
                });

                console.log(res);

                this.tableData1 = res.data.records;
                this.totalDialog = res.data.total;
                this.loading = false;
                this.goToPage('/ygzdxq?userName='+ encodeURIComponent(userName)+'&creationtime='+encodeURIComponent(creationtime)+'&settlement='+encodeURIComponent(settlement));
                // 打印tableData1的值
                console.log(this.tableData1);

                this.$forceUpdate();
                this.dialogVisible = true;
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        goToPage(url) {
            window.location.href = url;
        },

        closeDetailDialog() {
            this.detailDialog = false;
        },

        reset() {

            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;
            request.get("/building/findzd", {
                params: {
                    pageNum: 1,
                    pageSize: this.pageSize,
                    search: userName
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        filterTag(value, row) {
            return row.finish === value;
        },
        filterTag1(value, row) {
            return row.audit === value;
        },
        filterTag2(value, row) {
            return row.settlement === value;
        },

        cancel() {
            this.$refs.form.resetFields();
            this.dialogVisible = false;
        },
        showDetail(row) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.detail = row;
            });
        },

        handleSizeChange(pageSize) {
            //改变每页个数
            this.pageSize = pageSize;
            this.load();
        },
        handleCurrentChange(pageNum) {
            //改变页码
            this.currentPage = pageNum;
            this.load();
        },
        handleSizeChangeDialog(pageSizeDialog) {
            // 改变每页个数
            this.pageSizeDialog = pageSizeDialog;
            this.load1();
        },
        handleCurrentChangeDialog(currentPageDialog) {
            // 改变页码
            this.currentPageDialog = currentPageDialog;
            this.load1();
        },
    },
};